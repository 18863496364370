<template>
  <v-main>
    <Modal
      :show="showPasswordResetModal"
      @toggle="togglePasswordResetModal"
      :title="$t('userProfile.passwordReset')"
    >
      <PasswordResetForm
        @resetPassword="resetPassword"
        @toggle="togglePasswordResetModal"
      />
    </Modal>
    <v-card width="400" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1">{{ $t('title.login') }}</h1>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            :label="$t('userRegistration.email')"
            v-model="email"
            prepend-icon="mdi-account-circle"
          ></v-text-field>
          <v-text-field
            :label="$t('userRegistration.password')"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn class="error" @click="togglePasswordResetModal">{{
          $t('userRegistration.forgotPassword')
        }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="info" @click="login">{{ $t('buttons.login') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-main>
</template>

<script>
import Modal from './../components/Modal'
import PasswordResetForm from './../components/PasswordResetForm'
export default {
  name: 'Login',
  components: {
    Modal,
    PasswordResetForm,
  },
  data: () => {
    return {
      showPasswordResetModal: false,
      showPassword: false,
      password: '',
      email: '',
    }
  },
  methods: {
    togglePasswordResetModal() {
      this.showPasswordResetModal = !this.showPasswordResetModal
    },
    resetPassword(email) {
      this.$store.dispatch('user/resetPassword', { email })
    },
    login() {
      this.$store.dispatch('user/login', {
        email: this.email,
        password: this.password,
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
